import React, { useState } from "react";
import "../css/Header.css";
import { HiOutlineSearch } from "react-icons/hi";
import { connect } from "react-redux";
import ProfileVisibleModal from "./ProfileVisibleModal";
import { withRouter } from "./withRouter";
import action from "../store/action";
import { edituser } from "../Path/Path";
import store from "../store";
import getImageURL from "../config/uploadmageFunction";
import { toast } from "react-toastify";
const Header = ({ head, paraDash, user, getLoading }) => {
  const [createdAt, setCreatedAt] = useState("");
  const [id, setId] = useState("");
  const [storeName, setStoreName] = useState("");
  const [triggeredTime, setTriggeredTime] = useState("");
  const [delegateTo, setDelegateTo] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [ViewVisible, setViewVisible] = useState(false);
  const [userName, setUserName] = useState(
    store.getState().user?.userName ? store.getState().user?.userName : ""
  );

  const [imgData, setImgData] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const updateHandlerImage = async () => {
    getLoading(true);
    console.log(imgData, "imgData.....");
    let res = await getImageURL(imgFile);
    return res;
    // getLoading(false)
  };

  const editHandler = async () => {
    setViewVisible(false);
    if (!userName) {
      toast.warn("Enter Username");
    } else {
      if (imgData) {
        let profileLink = await updateHandlerImage();
        const body = {
          userName: userName,
          image: profileLink,
        };
        let res = await edituser(body, store.getState().user?._id);
        if (res?.success) {
          let user = store.getState().user;
          user = { ...user, ...body };
          store.dispatch(action.set_updateProfile(user));
          toast.success("User Edited Successfully");
          setImgData(null);
          setViewVisible(false);
          getLoading(false);
        } else {
          getLoading(false);
        }
      } else {
        getLoading(true);
        const body = {
          userName: userName,
        };
        let res = await edituser(body, store.getState().user?._id);
        alert("Hi");
        if (res?.success) {
          let user = store.getState().user;
          user = { ...user, ...body };
          store.dispatch(action.set_updateProfile(user));
          toast.success("User Edited Successfully");
          setImgData(null);
          setViewVisible(false);
          getLoading(false);
        } else {
          getLoading(false);
        }
      }
    }
  };
  return (
    <div className="main-div-header">
      <div className="header-flex">
        <div className="header-top">
          <h2 className="heading">{head}</h2>
          <p className="header-para">{paraDash}</p>
        </div>
        {/* <div className='search-header'>
                    <div className='search-icon'><HiOutlineSearch /></div>
                    <input type="text" placeholder='Search everything' />
                </div> */}
        {/* <div style={{ cursor: "pointer" }} onClick={() => setViewVisible(true)} className='profile'> */}
        {/* <div style={{ cursor: "pointer" }} className="profile">
          <div className="profile-img">
            <img src={require("../assets/pic.jpg")} alt="" />
          </div>
          <div style={{ cursor: "pointer" }} className="profile-text">
            <p className="person-name">{user?.userName}</p>
            <p className="person-admin">Admin account</p>
          </div>
        </div> */}
      </div>
      {/* <ProfileVisibleModal
                open={ViewVisible ? true : false}
                getImage={(e) => setImgData(e)}
                getFile={(e) => setImgFile(e)}
                getUserName={(e) => setUserName(e)}
                image={imgData}
                userName={userName}
                onClick={() => setViewVisible(false)}
                onUpdate={() => editHandler()}
            /> */}
    </div>
  );
};
const mapStatetoProps = (state) => {
  return {
    user: state.user,
    lang: state.lang,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    _setUsers: (payload) => dispatch(action.setUsers(payload)),
    _changeLang: (payload) => dispatch(action.changeLang(payload)),
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(Header));
// export default connect(state => state)(Header);
