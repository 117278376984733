import React, { useEffect, useState } from "react";
import "../css/Dashboard.css";
import "../css/DropDown.css";
import "../css/Account.css";
import "../css/Table.css";
import Header from "../Componenets/Header";
import Loader from "../Componenets/Loader";
import { withRouter } from "../Componenets/withRouter";
import action from "../store/action";
import { connect } from "react-redux";
import axios from "axios";
import FilteredData from "./FilteredData";
import Filter from "./Filter";

const url = "https://api.centrelizedback.com/apis/clients";

const Clients = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    status: "",
    email: "",
    phone: "",
    ip: "",
    source: "",
    country: { value: "All", label: "All" },
    dateGte: "",
    dateLte: "",
  });
  useEffect(() => {
    // Get APIs
    loadData({});
  }, []);

  const loadData = async (filterData) => {
    setLoading(true);
    axios
      .post(url, filterData)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setData(res.data.result);
        } else {
          alert(res.data.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert("Server Error !!");
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <Header
        getLoading={(e) => setLoading(e)}
        head={props.lang === "en" ? "Statistics" : "Statistics"}
        paraDash={props.lang === "en" ? "Clients List" : "Clients List"}
      />
      <div
        style={{
          width: "100%",
        }}
      >
        <Filter
          loadData={loadData}
          filterData={filterData}
          setFilterData={setFilterData}
        />
        {data && !loading && <FilteredData data={data} />}
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  return {
    user: state.user,
    lang: state.lang,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    _setUsers: (payload) => dispatch(action.setUsers(payload)),
    _changeLang: (payload) => dispatch(action.changeLang(payload)),
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(withRouter(Clients));
