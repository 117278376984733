import React from "react";
import "../css/Sidebar.css";
import { AiFillHome, AiFillDollarCircle, AiOutlineTeam } from "react-icons/ai";
import { ImUser, ImUserPlus, ImUsers } from "react-icons/im";
import { RiUserSettingsFill } from "react-icons/ri";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { AiFillAlert } from "react-icons/ai";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import action from "../store/action";
import { withRouter } from "./withRouter";
import { connect } from "react-redux";
import store from "../store";

const Sidebar = (props) => {
  const navigate = useNavigate();

  const path = window.location.pathname;
  const logout = () => {
    store.dispatch(action.logout());
    navigate("/");
  };
  return (
    <div className="main-div-sidebar">
      <div className="logo-flex">
        <div className="sidebar">
          <div className="logo-sidebar">
            <img
              style={{ width: "100px", height: "70px" }}
              src={require("../assets/triaden.png")}
              alt=""
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "-18%" }} className="link-sidebar">
        <div
          className={path === "/" ? "text-icon-sel" : "text-icon"}
          onClick={() => navigate("/")}
        >
          <div className={path === "/" ? "side-bar-icon-sel" : "side-bar-icon"}>
            <AiFillHome />
          </div>
          <p className={path === "/" ? "icon-para-sel" : "icon-para"}>
            {props.lang === "en" ? "APIs" : "APIs"}
          </p>
        </div>
        <div
          className={path === "/clients" ? "text-icon-sel" : "text-icon"}
          onClick={() => navigate("/clients")}
        >
          <div
            className={
              path === "/clients" ? "side-bar-icon-sel" : "side-bar-icon"
            }
          >
            <AiOutlineTeam />
          </div>
          <p className={path === "/clients" ? "icon-para-sel" : "icon-para"}>
            {props.lang === "en" ? "Clients" : "Clients"}
          </p>
        </div>

        <div onClick={logout} className="text-icon">
          <div className="side-bar-icon">
            <RiLogoutBoxRFill />
          </div>
          <p className="icon-para">
            {props.lang === "en" ? "Logout" : "Se déconnecter"}
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStatetoProps = (state) => {
  return {
    user: state.user,
    lang: state.lang,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    logout: () => dispatch(action.logout()),
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(withRouter(Sidebar));
