import axios from "axios";
import { useEffect, useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import "./filters.css";
import Select from "react-select";
const worldwideCountries = require("../helpers/countries.json");

const url = "https://api.centrelizedback.com/apis/selectSources";

const Filter = (props) => {
  const { filterData, setFilterData, loadData } = props;
  const [isActiveTab, SetIsActiveTab] = useState(
    localStorage.getItem("isActiveFilterTab") === "true"
  );
  const [sources, setSources] = useState([]);

  const handleInputChange = (event) => {
    console.log(event);
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event) => {
    setFilterData({
      ...filterData,
      country: event,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const Finalfilter = {
      ...filterData,
      status:
        filterData.status == "true"
          ? true
          : filterData.status == "false"
          ? false
          : "",
      countryCode:
        filterData.country.value == "All" ? "" : filterData.country.value,
    };
    delete Finalfilter.country;
    if (Finalfilter.source == "All") {
      Finalfilter.source = "";
    }
    console.log({ Finalfilter });
    setFilterData(Finalfilter);
    loadData(Finalfilter);
  };

  useEffect(() => {
    loadSources();
  }, []);

  const loadSources = async () => {
    axios
      .post(url)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setSources([...res.data.result]);
        } else {
          console.log(res.data.error);
        }
      })
      .catch((err) => {
        console.log("Server Error with Sources List !!");
      });
  };

  return (
    <div className="filters">
      <div
        style={{ fontWeight: "bold", fontSize: "18px" }}
        onClick={() => {
          SetIsActiveTab((e) => !e);
          localStorage.setItem("isActiveFilterTab", !isActiveTab);
        }}
      >
        {isActiveTab ? <AiFillCaretUp /> : <AiFillCaretDown />}
        Filters:
      </div>
      {isActiveTab && (
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%", padding: "10px" }}
        >
          <div className="row">
            <div className="col-md-4 col-4">
              <label>Status:</label>
              <select
                value={filterData.status}
                onChange={handleInputChange}
                className="input"
                name="status"
                defaultValue={null}
              >
                {[
                  { value: null, label: "All" },
                  { value: "true", label: "Sent to API" },
                  { value: "false", label: "Not Sent to API" },
                ].map((item, key) => {
                  return (
                    <option value={item.value} key={key}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-4 col-4">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={filterData.email}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-4 col-4">
              <label>Phone:</label>
              <input
                type="tel"
                name="phone"
                value={filterData.phone}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-4 col-4">
              <label>IP:</label>
              <input
                type="text"
                name="ip"
                value={filterData.ip}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-4 col-4">
              <label>Source:</label>
              <select
                value={filterData.source}
                onChange={handleInputChange}
                className="input"
                name="source"
                defaultValue={null}
              >
                <option value={null} key={0}>
                  {"All"}
                </option>
                {sources.map((item, key) => {
                  return (
                    <option value={item} key={key + 1}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-4 col-4">
              <label>Country:</label>
              <Select
                value={filterData.country}
                className="input"
                onChange={handleSelectChange}
                name="country"
                defaultValue={null}
                options={[
                  {
                    value: "All",
                    label: "All",
                  },
                  ...worldwideCountries.sort(function (a, b) {
                    const nameA = a.value.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.value.toUpperCase(); // ignore upper and lowercase
                    if (nameA > nameB) {
                      return 1;
                    }
                    if (nameA < nameB) {
                      return -1;
                    }

                    // names must be equal
                    return 0;
                  }),
                ]}
              />
            </div>

            <div className="col-md-6 col-6">
              <label>From Date:</label>
              <input
                type="date"
                name="dateGte"
                value={filterData.dateGte}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-6 col-6">
              <label>To Date:</label>
              <input
                type="date"
                name="dateLte"
                value={filterData.dateLte}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div style={{ width: "100%", display: "flex" }}>
            <button type="submit" className="BtnSubmit">
              Filter
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Filter;
