import axios from "axios";

const Path = "https://api.centrelizedback.com";
const PathTest = "https://api.profitsimulator.help";

const signup = async (payLoad) => {
  const { data } = await axios.post(`${Path}/user/signup`, payLoad);
  return data;
};
const login = async (payLoad) => {
  const { data } = await axios.post(`${Path}/user/login`, payLoad);
  return data;
};
const getUsers = async () => {
  const { data } = await axios.get(`${Path}/user/get/all`);
  return data;
};
const getApps = async () => {
  const { data } = await axios.get(`${Path}/screenEnabling/get`);
  return data;
};
const editApp = async (id, body) => {
  const { data } = await axios.put(`${Path}/screenEnabling/edit/` + id, body);
  return data;
};
const addNewApp = async (body) => {
  const { data } = await axios.post(`${Path}/screenEnabling/add`, body);
  return data;
};
const getCustomerSupport = async () => {
  const { data } = await axios.get(`${Path}/customerCare/get`);
  return data;
};
const deleteuser = async (item) => {
  const { data } = await axios.delete(
    `${Path}/user/delete/${item?.email}/${item?._id}`
  );
  return data;
};
const edituser = async (body, _id) => {
  console.log(body, _id, "id.....");
  const { data } = await axios.patch(`${Path}/user/edit/${_id}`, body);
  return data;
};
const customerSupportEdit = async (body, _id) => {
  console.log(body, _id, "id.....");
  const { data } = await axios.put(`${Path}/customerCare/edit/${_id}`, body);
  return data;
};
const areaGraph = async () => {
  const { data } = await axios.get(`${Path}/notification/get/by/time`);
  return data;
};
const barGraph = async () => {
  const { data } = await axios.get(`${Path}/user/get/by/time`);
  return data;
};
const getPie = async () => {
  const { data } = await axios.get(`${Path}/notification/get/all`);
  return data;
};
const deletenotification = async (item) => {
  const { data } = await axios.delete(
    `${Path}/notification/delete/${item._id}`
  );
  return data;
};
const getUserResult = async () => {
  const { data } = await axios.get(`${Path}/user/get/summary`);
  return data;
};
const getAllMall = async () => {
  const { data } = await axios.get(`${Path}/mall/get/all`);
  return data;
};
const getMalById = async (_id) => {
  const { data } = await axios.get(`${Path}/mall/get/${_id}`);
  return data;
};
const editMallById = async (body, _id) => {
  const { data } = await axios.put(`${Path}/mall/edit/${_id}`, body);
  return data;
};
const deleteMall = async (item) => {
  const { data } = await axios.delete(`${Path}/mall/delete/${item._id}`);
  return data;
};
const createMall = async (body) => {
  const { data } = await axios.post(`${Path}/mall/add`, body);
  return data;
};
const verifyCodeForget = async (params) => {
  const { data } = await axios.get(
    `${Path}/user/send-reset-pass/${params.email}/${params.type}`
  );
  return data;
};
const resetPassword = async (email, body) => {
  const { data } = await axios.put(
    `${Path}/user/reset/password/${email}`,
    body
  );
  return data;
};
const getNotificationsById = async (_id) => {
  const { data } = await axios.get(`${Path}/notification/${_id}`);
  return data;
};
const upsertNotifications = async (payload) => {
  const { data } = await axios.post(`${Path}/notification`, payload);
  return data;
};
const deleteNotificationsById = async (_id) => {
  const { data } = await axios.delete(`${Path}/notification/${_id}`);
  return data;
};
export {
  signup,
  login,
  getUsers,
  deleteuser,
  edituser,
  areaGraph,
  barGraph,
  getPie,
  deletenotification,
  getUserResult,
  getAllMall,
  getMalById,
  editMallById,
  deleteMall,
  createMall,
  getCustomerSupport,
  verifyCodeForget,
  resetPassword,
  customerSupportEdit,
  getApps,
  editApp,
  addNewApp,
  getNotificationsById,
  upsertNotifications,
  deleteNotificationsById,
};
