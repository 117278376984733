import React from "react";
import { Table } from "antd";
import moment from "moment";

const FilteredData = (props) => {
  const { data } = props;

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "API Destination",
      dataIndex: "api",
      key: "api",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Country Code",
      dataIndex: "countryCode",
      key: "countryCode",
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
  ];

  return <Table dataSource={data} columns={columns} />;
};

export default FilteredData;
