import React, { useEffect, useState } from "react";
import "../css/Dashboard.css";
import "../css/DropDown.css";
import "../css/Account.css";
import "../css/Table.css";
import Header from "../Componenets/Header";
import Loader from "../Componenets/Loader";
import { withRouter } from "../Componenets/withRouter";
import action from "../store/action";
import { connect } from "react-redux";
import axios from "axios";
import APIsEditor from "./APIsEditor";

const url = "https://api.centrelizedback.com/apis/getAPIs";

const Dashboard = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    // Get APIs
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    axios
      .post(url)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
        } else {
          alert(res.data.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert("Server Error !!");
        setLoading(false);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <Header
        getLoading={(e) => setLoading(e)}
        head={props.lang === "en" ? "APIs" : "APIs"}
        paraDash={props.lang === "en" ? "APIs Manager" : "APIs Manager"}
      />
      <div
        style={{
          width: "100%",
        }}
      >
        {data && <APIsEditor data={data} />}
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  return {
    user: state.user,
    lang: state.lang,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    _setUsers: (payload) => dispatch(action.setUsers(payload)),
    _changeLang: (payload) => dispatch(action.changeLang(payload)),
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(withRouter(Dashboard));
