import { Formik, Form, Field, FieldArray } from "formik";
import "./apis.css";
import axios from "axios";
import CustomSelect from "./CustomSelect";
const worldwideCountries = require("../helpers/countries.json");

const url = "https://api.centrelizedback.com/apis/editAPI";

function App(props) {
  const initialValues = props.data;
  const onSubmit = (values) => {
    console.log(values);
    if (values.partners.filter((item) => item.isOnly).length > 1) {
      alert("You should select only one App as 'isOnly' !!");
    } else {
      if (values.partners.filter((item) => item.status).length == 0) {
        alert("At least one app should be activated !!");
      } else {
        const data = values.partners.map((item) => {
          return {
            ID: item.ID,
            description: item.description,
            includedCountries: item.includedCountries,
            excludedCountries: item.excludedCountries,
            status: item.status,
            isOnly: item.isOnly,
          };
        });
        axios
          .post(url, data)
          .then((res) => {
            if (res.data.success) {
              alert("Success !!");
              window.location.reload();
            } else {
              alert(res.data.error);
            }
          })
          .catch((err) => {
            alert("Server Error !!");
          });
      }
    }
  };

  return (
    <Formik
      initialValues={{ partners: initialValues }}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form
          style={{
            marginBottom: "30px",
          }}
        >
          {values.partners.map((partner, index) => {
            console.log(values);
            return (
              <div
                key={index}
                style={{
                  width: "99%",
                  marginBottom: "10px",
                  border: "1px solid #878282dd",
                  borderRadius: "8px",
                  background: "#f8f8f8",
                  boxShadow: "rgba(1, 1, 1, 1) 0px 1px 11px -6px",
                  padding: "10px",
                }}
              >
                <div>
                  <h2 style={{ textAlign: "center" }}>{partner.ID}</h2>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <label htmlFor={`partners.${index}.description`}>
                    Description
                  </label>
                  <Field name={`partners.${index}.description`} />
                </div>

                <div style={{ marginTop: "20px" }}>
                  {/* <FieldArray name={`partners.${index}.includedCountries`}>
                  {(arrayHelpers) => (
                    <div> */}
                  <label>Included Countries</label>
                  <Field
                    name={`partners.${index}.includedCountries`}
                    // type="select"
                    options={[
                      {
                        value: "All",
                        label: "All",
                      },
                      ...worldwideCountries.sort(function (a, b) {
                        const nameA = a.value.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.value.toUpperCase(); // ignore upper and lowercase
                        if (nameA > nameB) {
                          return 1;
                        }
                        if (nameA < nameB) {
                          return -1;
                        }

                        // names must be equal
                        return 0;
                      }),
                    ]}
                    component={CustomSelect}
                    placeholder="Select multi Countries..."
                    isMulti={true}
                    className="input"
                  />

                  {/* {partner.includedCountries.map(
                        (country, countryIndex) => (
                          <div key={countryIndex} style={{ marginTop: "10px" }}>
                            <Field
                              name={`partners.${index}.includedCountries.${countryIndex}`}
                            />
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(countryIndex)}
                              className="BtnRemove"
                            >
                              -
                            </button>
                          </div>
                        )
                      )}
                      <div style={{ display: "flex", width: "100%" }}>
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                          className="BtnAdd"
                        >
                          Add Included Country
                        </button>
                      </div> */}
                  {/* </div>
                  )}
                </FieldArray> */}
                </div>

                <div style={{ marginTop: "20px" }}>
                  {/* <FieldArray name={`partners.${index}.excludedCountries`}>
                  {(arrayHelpers) => (
                    <div> */}
                  <label>Excluded Countries</label>
                  <Field
                    name={`partners.${index}.excludedCountries`}
                    // type="select"
                    options={[
                      {
                        value: "All",
                        label: "All",
                      },
                      ...worldwideCountries.sort(function (a, b) {
                        const nameA = a.value.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.value.toUpperCase(); // ignore upper and lowercase
                        if (nameA > nameB) {
                          return 1;
                        }
                        if (nameA < nameB) {
                          return -1;
                        }

                        // names must be equal
                        return 0;
                      }),
                    ]}
                    component={CustomSelect}
                    placeholder="Select multi Countries..."
                    isMulti={true}
                    className="input"
                  />
                  {/* {partner.excludedCountries.map(
                        (country, countryIndex) => (
                          <div key={countryIndex} style={{ marginTop: "10px" }}>
                            <Field
                              name={`partners.${index}.excludedCountries.${countryIndex}`}
                            />
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(countryIndex)}
                              className="BtnRemove"
                            >
                              -
                            </button>
                          </div>
                        )
                      )}
                      <div style={{ display: "flex", width: "100%" }}>
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                          className="BtnAdd"
                        >
                          Add Excluded Country
                        </button>
                      </div> */}
                  {/* </div>
                  )}
                </FieldArray> */}
                </div>

                <div style={{ marginTop: "20px" }}>
                  <label htmlFor={`partners.${index}.status`}>Status</label>
                  <Field type="checkbox" name={`partners.${index}.status`} />
                </div>

                <div style={{ marginTop: "20px" }}>
                  <label htmlFor={`partners.${index}.isOnly`}>Is Only</label>
                  <Field type="checkbox" name={`partners.${index}.isOnly`} />
                </div>
              </div>
            );
          })}
          <button type="submit" className="BtnSubmit">
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default App;
